const getters = {
  token: (state) => state.login.token,
  userId: (state) => state.user.userId,
  role: (state) => state.user.role,
  name: (state) => state.user.name,
  tel: (state) => state.user.tel,
  photo: (state) => state.user.photo,
  openOrder: (state) => state.user.openOrder,
  allowance: (state) => state.user.allowance,
  discountConfig: (state) => state.user.discountConfig,
  manager: (state) => state.user.manager,
  addressDefault: (state) => state.user.addressDefault,
  menuSetting: (state) => state.user.menuSetting

  // list: state => state.user.list,
  // phone: state => state.user.phone,
  // cardno: state => state.user.cardno,
  // id: state => state.user.id
};
export default getters;
